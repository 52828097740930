import React, { useState, useEffect, Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { emitter } from 'services';
import { displayMonthFromConcessionMonth, getDefaultEndAtDate, getDefaultConcessionMonth, API } from '../utils';
import { TextField, MenuItem, Box } from '@material-ui/core';
import CustomInput from '../../../components/CustomInput';
import ValidationError from '../../../components/validation/ValidationError';
import ValidationRequired from '../../../components/validation/ValidationRequired';
import DatePicker from 'react-datepicker';
import { Flex } from 'grid-styled';
import UploadIcon from '../../../icons/upload.svg';
import calenderIcon from '../../../icons/calendar.svg';
import moment from 'moment';
import Papa from 'papaparse';
import { toSnakeCase, snakeToTitleCase } from 'utils/index';
import { Button, Message, Form as UIForm } from 'semantic-ui-react';
import axios from 'utils/axios';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import RenderTable from './RenderTable';

const Form = (props) => {
  const params = useParams();
  const history = useHistory();
  const { readonly, status = {} } = props;
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [parseCSVError, setParseCSVError] = useState({ hasError: false, msg: '' });
  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting, isLoading, isValidating, submitCount },
    watch,
    setValue,
    reset,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      message: '',
      title: '',
      description: '',
      start_at: moment.utc().format('MM/DD/YYYY'),
      end_at: getDefaultEndAtDate(),
      concession_month: getDefaultConcessionMonth(),
      created_by: axios.getUserId(),
      file: null,
      files: null,
    },
  });
  const values = watch();
  const [data, setData] = useState(null);
  const fileUploadRef = useRef(null);
  const monthOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => ({
    value: num,
    label: displayMonthFromConcessionMonth(num),
  }));
  useEffect(() => {
    if (params.specialId) {
      API.get(params.specialId, true).then((response) => {
        reset({
          ...response.data,
          start_at: moment.utc(response.data.start_at).format('MM/DD/YYYY'),
          end_at: moment.utc(response.data.end_at).format('MM/DD/YYYY'),
        });
      });
    }
  }, [params.specialId]);
  /**
   * Get the label for a month number from the array above.
   * @param {*} num maps to a month (1-12)
   * @returns {string} the name of the month.
   */
  const formatMonth = (num) => {
    const month = monthOptions.find((option) => option.value === parseInt(num, 10));
    return month && month.label;
  };
  const getTrProps = (state, rowInfo, instance) => {
    if (rowInfo.original.valid) {
      return {
        style: {
          background: rowInfo.original.valid === 'true' ? 'rgba(0,255,0,0.5)' : 'rgba(255,0,0,0.5)',
        },
      };
    }
    return {};
  };

  const constructTableData = (data) => {
    if (data.length === 0) return;
    const dataLocation = data[0];
    const selectedData = data;

    return {
      columns: Object.keys(dataLocation).map((key) => {
        return {
          Header: snakeToTitleCase(key),
          accessor: key,
          minWidth: key === 'reason' ? 250 : 105,
        };
      }),
      data: selectedData.map((row) => {
        return Object.keys(row).reduce((acc, key) => {
          return {
            ...acc,
            [key]: `${row[key]}`,
          };
        }, {});
      }),
    };
  };

  const handleImport = (properties, start_at, end_at) => {
    return axios
      .post('/api/admin/process-specials', { properties, start_at, end_at })
      .then((response) => {
        const tableInfo = constructTableData(response.data);
        setData(tableInfo);
        setValue('files', tableInfo.data);
        setIsFileLoading(false);
        return;
      })
      .catch((error) => {
        throw error;
      });
  };

  const handleFileChange = (e) => {
    setIsFileLoading(true);
    if (e) e.preventDefault();
    setValue('file', e.target.files[0]);
    Papa.parse(e.target.files[0], {
      header: true,
      transformHeader: (header) => toSnakeCase(header),
      skipEmptyLines: true,
      chunk: async (results) => {
        await handleImport(results.data, values.start_at, values.end_at);
      },
    });
  };

  const showFileDialog = (e) => {
    e && e.preventDefault();
    document.getElementById('upload_file').click();
  };

  const readOnlyField = (label, value, nl2br = false) => {
    return (
      <Fragment>
        <label>
          <strong>{label}</strong>
        </label>

        {nl2br ? (
          <p>
            {value.split('\n').map((item, key) => (
              <Fragment key={key}>
                {item}
                <br />
              </Fragment>
            ))}
          </p>
        ) : (
          <p>{value}</p>
        )}
      </Fragment>
    );
  };

  const createDatePickerChangeHandler = (field) => (momentDate) => {
    setValue(field, moment.utc(momentDate).format('MM/DD/YYYY'));
    if (!values.files) return;
    if (values.files && field === 'start_at') {
      return handleImport(values.files, moment.utc(momentDate).format('MM/DD/YYYY'), values.end_at);
    } else if (values.file && field === 'end_at') {
      return handleImport(values.files, values.start_at, moment.utc(momentDate).format('MM/DD/YYYY'));
    }
  };

  const hasSubmitted = submitCount > 0;

  // determine if form should be able to be submitted based on state
  const allowSubmitting = !readonly || !isSubmitting || isValid || !isValidating || !isLoading;

  const buttonClasses = classNames('ui button primary', {
    disabled: !allowSubmitting,
  });

  const characterLimit = 300;

  const handleCharacterLimit = (event) => {
    setValue(event.target.name, event.target.value);
  };

  const onSubmit = (data) => {
    setParseCSVError({ hasError: false, msg: '' });
    const body = {
      name: data.name,
      message: data.message,
      title: data.title,
      description: data.description,
      start_at: data.start_at,
      end_at: data.end_at,
      concession_month: data.concession_month,
    };
    let request;
    try {
      if (!params.specialId) {
        request = API.create({
          ...body,
          created_by: data.created_by,
          files: data.files.filter((file) => file.valid === 'true'),
        });
      } else {
        request = API.edit(params.specialId, body);
      }
    } catch (err) {
      setParseCSVError({
        hasError: true,
        msg: 'File Corrupted: The file you are trying to upload seems to be corrupted and cannot be processed. Please ensure that the file is a CSV format.',
      });
    }

    request
      .then((response) => {
        // redirect to detail page for special.
        emitter.emit('alert-queue:add', {
          type: 'success',
          message: `Successfully ${params.specialId ? 'updated' : 'added'} special.`,
        });
        let redirectUri = '/leasing-specials';
        if (params.specialId) {
          redirectUri = `${redirectUri}/view/${params.specialId}`;
        }
        history.push(redirectUri);
      })
      .catch((err) => {
        setParseCSVError({
          hasError: true,
          msg: 'File Corrupted: The file you are trying to upload seems to be corrupted and cannot be processed. Please ensure that the file is a CSV format.',
        });
      });
  };
  return (
    <div>
      {' '}
      <UIForm onSubmit={handleSubmit(onSubmit)}>
        {!isSubmitting && hasSubmitted && status.error && (
          <Box pt={10} pb={10} width={1}>
            <Message negative>
              <span dangerouslySetInnerHTML={{ __html: status.error }} />
            </Message>
          </Box>
        )}
        <Box pl="2%" pb={20}>
          <Box>
            <Box pb="20px" width={[1, 1, 1 / 4]}>
              {readonly ? (
                readOnlyField('Start Date', values.start_at)
              ) : (
                <CustomInput
                  withValidation
                  validationError={hasSubmitted && errors.start_at}
                  label={'Start Date'}
                  name="start_at"
                  placeholder="MM/DD/YYYY"
                  value={moment(values.start_at).format('MM/DD/YYYY')}
                  LabelComponent={
                    <DatePicker
                      customInput={<img alt="date" src={calenderIcon} />}
                      selected={moment(values.start_at).toDate()}
                      popperPlacement="top-end"
                      {...register('start_at', { required: true })}
                      onChange={createDatePickerChangeHandler('start_at')}
                    />
                  }
                />
              )}
            </Box>
            <Box pb="20px" width={[1, 1, 1 / 4]}>
              {readonly ? (
                readOnlyField('End Date', values.end_at)
              ) : (
                <CustomInput
                  withValidation
                  validationError={hasSubmitted && errors.end_at}
                  label={'End Date'}
                  name="end_at"
                  placeholder="MM/DD/YYYY"
                  value={moment(values.end_at).format('MM/DD/YYYY')}
                  LabelComponent={
                    <DatePicker
                      customInput={<img alt="date" src={calenderIcon} />}
                      selected={moment(values.end_at).toDate()}
                      popperPlacement="top-end"
                      {...register('end_at', { required: true })}
                      onChange={createDatePickerChangeHandler('end_at')}
                    />
                  }
                />
              )}
            </Box>
            <Box pb="20px" width={[1, 1, 1 / 4]}>
              {readonly ? (
                readOnlyField('Month Concession Takes Effect', formatMonth(values.concession_month))
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <label>
                    <strong>
                      Month Concession Takes Effect <ValidationRequired />
                    </strong>
                  </label>
                  <TextField
                    select
                    placeholder="Select"
                    variant="outlined"
                    size="small"
                    style={{ marginTop: '5px' }}
                    {...register('concession_month', { required: 'Must enter a concession month' })}
                    defaultValue={values.concession_month}
                  >
                    {monthOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {errors.concession_month && (
                    <ValidationError>{hasSubmitted && errors.concession_month}</ValidationError>
                  )}
                </Box>
              )}
            </Box>
            <Box pb="20px">
              {readonly ? (
                readOnlyField('Leasing Special Name', values.name)
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  {' '}
                  <label>
                    <strong>
                      Leasing Special Name <ValidationRequired />
                    </strong>
                  </label>
                  <TextField
                    placeholder="Enter name"
                    {...register('name', { required: 'Must provide a leasing special name' })}
                    InputProps={{ disableUnderline: true }}
                    size="small"
                    style={{ marginTop: '5px', border: 'none' }}
                  />
                  {errors.concession_month && <ValidationError>{hasSubmitted && errors.name}</ValidationError>}
                </Box>
              )}
            </Box>
            <Box pb="20px">
              {readonly ? (
                readOnlyField('Leasing Special Message', values.message, true)
              ) : (
                <div>
                  <label>
                    <strong>Leasing Special Message</strong>
                  </label>
                  <br />
                  <TextField
                    {...register('message')}
                    multiline
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    minRows="3"
                  />
                </div>
              )}
            </Box>
            <Box pb="20px">
              {readonly ? (
                readOnlyField('Leasing Special Title', values.title)
              ) : (
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <label>
                    <strong>Leasing Special Title</strong>
                  </label>
                  <TextField
                    name="title"
                    placeholder="Enter title"
                    {...register('title')}
                    value={values.title}
                    InputProps={{ disableUnderline: true }}
                    size="small"
                    style={{ marginTop: '5px', border: 'none' }}
                    inputProps={{ maxLength: characterLimit }}
                    onChange={(e) => handleCharacterLimit(e)}
                    helperText={`${values.title.length}/${characterLimit}`}
                  />
                </Box>
              )}
            </Box>
            <Box pb="20px">
              {readonly ? (
                readOnlyField('Leasing Special Description', values.description)
              ) : (
                <div>
                  <label>
                    <strong>Leasing Special Description</strong>
                  </label>
                  <br />
                  <TextField
                    {...register('description')}
                    multiline
                    fullWidth
                    InputProps={{ disableUnderline: true }}
                    minRows="3"
                    inputProps={{ maxLength: characterLimit }}
                    onChange={(e) => handleCharacterLimit(e)}
                    helperText={`${values.description.length}/${characterLimit}`}
                  />
                </div>
              )}
            </Box>
            {!params.specialId ? (
              <Box pb="20px">
                <input
                  id="upload_file"
                  type="file"
                  name="file"
                  accept=".csv"
                  {...register('file', { required: 'Must provide a file' })}
                  onChange={handleFileChange}
                  ref={fileUploadRef}
                  style={{ opacity: 0, height: 0, width: 0 }}
                />
                <label>
                  <strong>
                    File <ValidationRequired />
                  </strong>
                </label>
                <Flex alignItems="center">
                  {!values.file ? (
                    <Button icon type="button" onClick={showFileDialog}>
                      Upload File
                      <img src={UploadIcon} alt="" style={{ marginLeft: '8px' }} />
                    </Button>
                  ) : (
                    <span style={{ paddingLeft: '2%' }}>
                      <strong>{values.file.name} </strong>
                      <Button
                        onClick={showFileDialog}
                        style={{
                          textDecoration: 'underline',
                          fontSize: '12px',
                        }}
                      >
                        {isFileLoading ? 'Loading' : 'Change'}
                      </Button>
                    </span>
                  )}
                  {hasSubmitted && errors.file && <ValidationError>{errors.file}</ValidationError>}
                </Flex>
                <RenderTable data={data?.data} columns={data?.columns} getTrProps={getTrProps} />
              </Box>
            ) : null}
          </Box>
        </Box>
        {parseCSVError.hasError && (
          <Message negative>
            <Message.Header>Unable to read CSV</Message.Header>
            <p>{parseCSVError.msg}.</p>
          </Message>
        )}
        <Flex justifyContent="flex-end" css="padding-top: 20px; padding-bottom: 20px; padding-right: 2%;">
          {readonly && params.specialId ? (
            <Link to={`/leasing-specials/edit/${params.specialId}`} className={buttonClasses}>
              Edit Special
            </Link>
          ) : (
            <Button type="submit" className={buttonClasses}>
              Save Changes
            </Button>
          )}
        </Flex>
      </UIForm>
    </div>
  );
};

export default withRouter(Form);
Form.propTypes = {
  status: PropTypes.object,
  readonly: PropTypes.bool,
};
